import {navigateTo, useNuxtApp} from '#imports';
import useEmailFromQueryParams from '~/composables/comatch/useEmailFromQueryParams';
import {callWithNuxt} from '#app';

const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export default function useLandingPageCheck() {
    const nuxtApp = useNuxtApp();
    const {email} = useEmailFromQueryParams();

    if (!re.test(email)) {
        callWithNuxt(nuxtApp, navigateTo, ['/c/comatch/error']);
    }
}
