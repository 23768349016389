import {useRoute} from '#imports';

export default function useEmailFromQueryParams() {
    const route = useRoute();
    const emailParam = route.query.email;
    const email = typeof emailParam === 'string' ? emailParam : emailParam?.[0] || '';

    return {
        email,
    };
}
